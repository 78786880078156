import { template as template_178aba1ae0994aeeb59635dbb0b3653e } from "@ember/template-compiler";
const EmptyState = template_178aba1ae0994aeeb59635dbb0b3653e(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
