import { template as template_252f06ab5bf24c46a0b0f23b9d046271 } from "@ember/template-compiler";
const WelcomeHeader = template_252f06ab5bf24c46a0b0f23b9d046271(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
