import { template as template_b730c9d9a95347a18ba3dc09ae52d32b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_b730c9d9a95347a18ba3dc09ae52d32b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
