import { template as template_2eb5e19af4b943a083b2df10a1fce1ce } from "@ember/template-compiler";
const FKLabel = template_2eb5e19af4b943a083b2df10a1fce1ce(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
